.contact-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    
    >p {
        font-size: 48px;
    }

    .contact-content {
        display: flex;
        width: 100%;
        padding: $sp20 0;

        .contact-address {
            margin-right: $sp12;
            flex: 1;

            .address {
                text-align: left;
                border-top: 1px solid $mediumGray;
                p {
                    margin-bottom: $sp12;
                }
                
                .title {
                    color: #141ED2;
                    font-weight: $semiBold;
                    margin-top: $sp12;
                }
            }
        }

        .contact-map {
            flex: 1;
            iframe {
                border: none !important;
                border-radius: $sp12;
                width: 100%;
            }
        }

    }



    @media screen and(max-width: 425px) {
        >p {
            font-size: 16px;
        }

        .contact-content {
            flex-direction: column;

            .contact-address {
                margin-left: $sp12;
            }
        }
    }

    @media screen and (max-width: 768px) {
        .contact-content {
            width: 95%;
        }
    }
    
}

.contact-info {
    display: flex;
    align-items: center;
    background-color: $lightGray;

    .contact-left {
        flex: 1;
        height: 650px;
        position: relative;

        .back-ground {
            width: 100%;
            height: 100%;

            img {
                object-fit: cover;
            }
        }

        .content {
            position: absolute;
            top: 30%;
            width: 100%;
            left: 10%;
            display: flex;
            justify-content:  center;
            flex-direction: column;
            align-items: flex-start;

            p {
                font-size: $sp32;
            }
        }
    }

    .contact-right {
        flex: 1;
        .contact-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 60%;
            margin: auto;

            .contact-input {
                background-color: white;
                padding: $sp12;
                border-radius: $sp8;
                margin-bottom: $sp12;
                width: 100%;
                box-shadow: 0px 4px 20px rgba(87, 87, 87, 0.25);

                .MuiTextField-root {
                    width: 100%;

                    .MuiInput-root {
                        &::before {
                            border: none !important;
                        }
                    }
                }
            }

            .contact-phone-input {
                background-color: white;
                padding: 0 $sp12;
                border-radius: $sp8;
                margin-bottom: $sp12;
                width: 100%;
                box-shadow: 0px 4px 20px rgba(87, 87, 87, 0.25);

                .MuiInputBase-root {
                    .MuiInputAdornment-positionStart {
                        display: none;
                    }
                }

                fieldset {
                    border: none;
                }
            }

            .btn {
                width: 105%;
            }
        }
    }

    @media screen and (max-width: 1440px) {

        .contact-left {
            .content {
                p {
                    font-size: 24px;
                }
            }
        }
    }

    @media screen and (max-width: 1024px) {

        .contact-left {
            .content {
                p {
                    font-size: 16px;
                }
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;

        .contact-left {
            .content {
                width: auto !important;
            }
        }

        .contact-right {
            width: 90% !important;

            .contact-form {
                width: 100%;
                margin-bottom: $sp12;

                .btn { width: 104%;}
            }
        }
    }

    @media screen and (max-width: 425px) {
        flex-direction: column;

            .contact-left {
                .content {
                    width: auto !important;
                    top: 20%;
                    left: 17%;

                    p {
                        font-size: 16px;
                    }
                }
            }

            .contact-right {
                width: 90% !important;

                .contact-form {
                    width: 100%;
                    margin-bottom: $sp12;

                    .btn { width: 104%;}
                }
            }
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}