.about-us {
    flex: 1;

    .MuiTabPanel-root {
        padding: 0;
    }

    .MuiTabs-flexContainer {
        justify-content: center;

        .MuiButtonBase-root {
            width: 30%;

            @media screen and (max-width: 425px) {
                width: 50%;
            }
        }
    }

    .tab-1 {
        .introduce {
            display: flex;
            flex-direction: column;
            margin: auto;
            padding-top: $sp40;

            .introduce-content {
                display: flex;
                margin-top: $sp20;
                flex-direction: column;
                width: 100%;
                justify-content: center;
                align-items: center;

                p {
                    font-size: $sp32;
                    width: 900;
                    color: $deepblue;
                    font-weight: 700;
                    margin-bottom: $sp40;

                }

                div {
                    padding: $sp20 0;
                    img {
                        width: 720px;
                        object-fit: cover;
                    }
                }

                @media screen and (max-width: 768px) {
                    flex-direction: column;

                    p {
                        font-size: $sp28;
                    }

                    div {
                        width: 100%;

                        img {
                            width: 700px;
                        }
                    }
                }

                @media screen and (max-width: 425px) {


                    div {
                        img {
                            width: 300px;
                        }
                    }
                }
            }
        }

        .history {
            padding-top: $sp40;
            background: radial-gradient(100% 886.1% at 100% 41.32%, #F5F5F5 0%, #39A0FF 0.01%, #0464CB 100%);

            .title-history {
                font-size: $sp32;
                display: flex;
                color: white;
                font-weight: 900;
                justify-content: center;
            }

            .history-swiper {
                background-color: transparent;
                padding: $sp24 0;

                .milestone {
                    width: 100%;
                    background-color: transparent;

                    .empty {
                        height: 60px;
                    }

                    .title {
                        position: relative;
                        color: white;

                        span {
                            content: '';
                            position: absolute;
                            width: 77%;
                            height: 16px;
                            background: white;
                            left: 0;
                            right: 0;
                            border-radius: 8px;
                            margin: 0 auto;
                        }

                        .polygon-top-left {
                            width: 29px;
                            height: 29px;
                            position: absolute;
                            bottom: -15px;
                            left: -15px;
                        }

                        .polygon-top-right {
                            width: 29px;
                            height: 29px;
                            position: absolute;
                            bottom: -15px;
                            right: -15px;
                        }

                        .polygon-bottom-left {
                            width: 29px;
                            height: 29px;
                            position: absolute;
                            top: -15px;
                            left: -15px;
                        }

                        .polygon-bottom-right {
                            width: 29px;
                            height: 29px;
                            position: absolute;
                            top: -15px;
                            right: -15px;
                        }
                    }

                    .summary {
                        min-height: 350px;
                        border-left: 1px solid white;
                        border-right: 1px solid white;
                        padding: $sp20;
                        color: white;
                        text-align: justify;
                        
                        p {
                            color: white;
                        }
                    }

                    .title-top {
                        border-bottom: 1px solid white;
                        padding: 0 $sp24 $sp24 $sp24;

                        span {
                            bottom: -8px;
                        }
                    }

                    .title-bottom {
                        border-top: 1px solid white;
                        padding: $sp24 $sp24 0 $sp24;

                        &:before {
                            top: -8px;
                            left: -8px;
                        }

                        &:after {
                            top: -8px;
                            right: -8px;
                        }

                        span {
                            top: -8px;
                        }
                    }
                }

                .swiper {
                    .swiper-wrapper {
                        width: 90%;
                        .swiper-slide {
                            margin-right: 0 !important;
                            background-color: transparent;
                            font-size: $sp16 !important;
    
                            &:nth-child(even) {
                                .milestone {
                                    .summary {
                                        display: flex;
                                        align-items: flex-end;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .price {
            max-width: 1200px;
            margin: auto;
            padding: $sp60 0 $sp60 0;
            width: 100vw;

            .title-price {
                font-size: $sp32;
                display: flex;
                color: $deepblue;
                font-weight: 900;
                justify-content: center;
                margin-bottom: $sp20;
            }

            .price-swiper {
                width: 100%;
                height: 350px;

                .price-items {
                    display: flex;
                    flex-direction: column;

                    .price-item-img {
                        width: 100%;
                        height: 240px;

                        img {
                            object-fit: contain;
                        }
                    }

                    p {
                        font-size: $sp20;
                        font-weight: bold;
                        line-height: $sp28;
                        text-align: center;
                    }
                }

                .swiper {
                    width: 100%;
                }
            }

            @media screen and (max-width: 425px) {
                max-width: 425px;
            }
        }
    }

    .tab-2 {
        width: 99vw;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        margin: $sp60 0;

        .title {
            font-size: $sp32;
            color: #1d2d3e;
            font-weight: 700;
            margin-bottom: $sp24;
        }

        img {
            height: 100%;
            width: 50%;
            object-fit: contain;
        }
    }

    .swiper {
        width: 98vw;
        margin: 0 auto;
        height: 100%;
    }

    .swiper-slide {
        text-align: center;
        font-size: 18px;
        background: #fff;

        /* Center slide text vertically */
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }


    @media screen and(max-width: 425px) {
        .tab-1 {
            .history {
                .title-history {
                    font-size: $fontL;
                }

                .history-swiper {
                    .swiper {
                        .swiper-wrapper {
                            width: 65%;
                        }
                    }
                }
            }

            .price {
                .title-price {
                    font-size: $fontL;
                }
            }
        }

        .tab-2 {
            .title {
                font-size: $sp24;
            }

            img {
                width: 100%;
            }
        }
    }
}

