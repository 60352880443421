.profile-right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 425px) {
        margin-left: $sp12;
        margin-right: $sp12;
    }
}

.content-detail {
    img {
        width: 100%;
    }
}